import React from 'react'
import { SvgAdd } from '@chilipiper/icons/src/design-system'
import {
  ActionBarButton,
  ActionBarButtonButtonProps,
  InternalProps,
} from '../action-bar-button/ActionBarButton'

export type ActionBarAddButtonProps<T> = Omit<T, 'startIcon'> & { variant: 'major' | 'minor' }
export const ActionBarAddButton = ({
  children,
  ...props
}: ActionBarAddButtonProps<ActionBarButtonButtonProps & InternalProps>) => (
  <ActionBarButton {...props} startIcon={SvgAdd}>
    {children}
  </ActionBarButton>
)
ActionBarAddButton.dsId = 'action-bar-add-button'
