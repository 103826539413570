import React from 'react'
import { createContextWithHook } from '@chilipiper/utils'
import { Icon } from '../../../../old/icon'
import { ThemeColors } from '../../../theme'
import { Avatar } from '../../wayfinding/avatar/Avatar'
import { AvatarGroup } from '../../wayfinding/avatar-group/AvatarGroup'
import { styled } from '../../../../styled-components'
import { Box } from '../../../core-components'

type User = React.ComponentPropsWithoutRef<typeof AvatarGroup>['users'][number]

type IconProps = {
  alt?: never
  icon: React.ComponentType
  id?: never
  image?: never
  name?: never
  users?: never
  variant: 'icon'
}

type AvatarGroupProps = {
  alt?: never
  icon?: never
  id?: never
  image?: never
  name?: never
  users: User[]
  variant: 'avatarGroup'
}

type AvatarProps = {
  alt?: never
  icon?: never
  id: User['id']
  image?: User['image']
  name: User['name']
  users?: never
  variant: 'avatar'
}

type ImageProps = {
  alt: string
  icon?: never
  id?: never
  image: string
  name?: never
  users?: never
  variant: 'image'
}

type Props = IconProps | AvatarGroupProps | AvatarProps | ImageProps

const { StartContentBaseProvider, useStartContentContext } = createContextWithHook<{
  iconColor?: ThemeColors
}>()('StartContent')

export const StartContentProvider = StartContentBaseProvider

export const StartContentIcon = styled(Icon)``

export const StartContent = (props: Props) => {
  const { iconColor } = useStartContentContext()

  if (props.variant === 'icon') {
    return (
      <StartContentIcon
        aria-hidden
        w={4}
        h={4}
        color={iconColor}
        icon={props.icon}
        flexShrink={0}
      />
    )
  }
  if (props.variant === 'avatar') {
    return <Avatar size={4} id={props.id} name={props.name} image={props.image} />
  }
  if (props.variant === 'image') {
    return (
      <StyledImage
        as='img'
        src={props.image}
        alt={props.alt}
        width={4}
        height={4}
        borderRadius={4}
      />
    )
  }
  return <AvatarGroup size={4} users={props.users} />
}

const StyledImage = styled(Box)`
  object-fit: cover;
`
