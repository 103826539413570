// Forked from https://github.com/adobe/react-spectrum/blob/f9143723fe44a1c2e03eaec93f1cc2dba4e020cf/packages/%40react-stately/menu/src/useSubmenuTriggerState.ts to add option to have initial expanded and onExpanded
import { useCallback, useMemo, useState } from 'react'
import { FocusStrategy, Key } from '@react-types/shared'
import type { OverlayTriggerState } from '@react-stately/overlays'
import { RootMenuTriggerState } from '@react-stately/menu'

export interface SubmenuTriggerProps {
  /** Key of the trigger item. */
  triggerKey: Key
}

export interface SubmenuTriggerState extends OverlayTriggerState {
  /** Closes the submenu. */
  close: () => void
  /** Closes all menus and submenus in the menu tree. */
  closeAll: () => void
  /** Controls which item will be auto focused when the submenu opens. */
  focusStrategy: FocusStrategy | null
  /** Whether the submenu is currently open. */
  isOpen: boolean
  /** Opens the submenu. */
  open: (focusStrategy?: FocusStrategy | null) => void
  /** @private */
  setOpen: () => void
  /** The level of the submenu. */
  submenuLevel: number
  /** Toggles the submenu. */
  toggle: (focusStrategy?: FocusStrategy | null) => void
}

/**
 * Manages state for a submenu trigger. Tracks whether the submenu is currently open, the level of
 * the submenu, and controls which item will receive focus when it opens.
 */
export const useSubmenuTriggerState = (
  props: SubmenuTriggerProps,
  state: RootMenuTriggerState
): SubmenuTriggerState => {
  const { triggerKey } = props
  const { expandedKeysStack, openSubmenu, closeSubmenu, close: closeAll } = state
  const submenuLevel = 0
  const isOpen = useMemo(
    () => expandedKeysStack[submenuLevel] === triggerKey,
    [expandedKeysStack, triggerKey, submenuLevel]
  )
  const [focusStrategy, setFocusStrategy] = useState<FocusStrategy | null>(null)

  const open = useCallback(
    (focusStrategy: FocusStrategy | null = null) => {
      setFocusStrategy(focusStrategy)
      openSubmenu(triggerKey, submenuLevel)
    },
    [openSubmenu, submenuLevel, triggerKey]
  )

  const close = useCallback(() => {
    setFocusStrategy(null)
    closeSubmenu(triggerKey, submenuLevel)
  }, [closeSubmenu, submenuLevel, triggerKey])

  const toggle = useCallback(
    (focusStrategy: FocusStrategy | null = null) => {
      setFocusStrategy(focusStrategy)
      if (isOpen) {
        close()
      } else {
        open(focusStrategy)
      }
    },
    [close, open, isOpen]
  )

  return useMemo(
    () => ({
      focusStrategy,
      isOpen,
      open,
      close,
      closeAll,
      submenuLevel,
      // TODO: Placeholders that aren't used but give us parity with OverlayTriggerState so we can use this in Popover. Refactor if we update Popover via
      // https://github.com/adobe/react-spectrum/pull/4976#discussion_r1336472863
      setOpen: () => {},
      toggle,
    }),
    [isOpen, open, close, closeAll, focusStrategy, toggle, submenuLevel]
  )
}
