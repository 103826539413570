import React from 'react'
import type { AriaProps } from '../../../types'
import { useAnchorProps, useButtonProps } from '../../hooks'
import { AnchorOnlyProps, ButtonOnlyProps } from '../../types'
import type { BaseActionBarButtonVariant } from '../BaseActionBarButton'
import { BaseActionBarButton } from '../BaseActionBarButton'

type CommonProps = {
  ariaProps?: AriaProps
  children: string | string[]
  'data-id'?: string
  'data-test-id'?: string
  isDisabled?: boolean
  isLoading?: boolean
  startIcon?: React.ComponentType
  tooltip?: string
  variant: BaseActionBarButtonVariant
}

export type InternalProps = {
  INTERNAL_hasNextSibling?: boolean
  INTERNAL_isFocusVisible?: boolean
  INTERNAL_isOnlyChild?: boolean
}

type PublicInternalProps = {
  INTERNAL_nativeOnClick?: (event: React.MouseEvent) => void
}

export type ActionBarButtonButtonProps = CommonProps & ButtonOnlyProps & PublicInternalProps

type ActionBarButtonAnchorProps = CommonProps & AnchorOnlyProps & PublicInternalProps

export type ActionBarButtonPublicProps = ActionBarButtonButtonProps | ActionBarButtonAnchorProps

type Props = ActionBarButtonPublicProps & InternalProps

export const ActionBarButton = (props: Props) => {
  if (props.as === 'a') {
    return <ActionBarButtonAsAnchor {...props} />
  } else {
    return <ActionBarButtonAsButton {...props} />
  }
}

const ActionBarButtonAsAnchor = ({
  variant,
  children,
  'data-id': dataId,
  ariaProps,
  INTERNAL_hasNextSibling,
  INTERNAL_isOnlyChild,
  startIcon,
  tooltip,
  href,
  onClick,
  rel,
  target,
  isDisabled,
  isLoading,
  INTERNAL_isFocusVisible,
  INTERNAL_nativeOnClick,
  'data-test-id': dataTestId,
}: ActionBarButtonAnchorProps & InternalProps) => {
  const { anchorProps, isFocusVisible, ref } = useAnchorProps({
    ...ariaProps,
    onClick,
    INTERNAL_isFocusVisible,
    INTERNAL_nativeOnClick,
    isDisabled: isDisabled || isLoading,
    rel,
    target,
    href,
  })

  return (
    <BaseActionBarButton
      variant={variant}
      wrapper={{ as: 'a', ref }}
      startIcon={startIcon}
      isLoading={isLoading}
      isFocusVisible={isFocusVisible}
      data-id={dataId}
      data-test-id={dataTestId}
      INTERNAL_isOnlyChild={INTERNAL_isOnlyChild}
      INTERNAL_hasNextSibling={INTERNAL_hasNextSibling}
      tooltip={tooltip}
      {...anchorProps}
    >
      {children}
    </BaseActionBarButton>
  )
}

const ActionBarButtonAsButton = ({
  isDisabled,
  variant,
  children,
  'data-id': dataId,
  ariaProps,
  INTERNAL_isFocusVisible,
  'data-test-id': dataTestId,
  onClick,
  INTERNAL_hasNextSibling,
  INTERNAL_isOnlyChild,
  startIcon,
  isLoading,
  tooltip,
}: ActionBarButtonButtonProps & InternalProps) => {
  const { ref, isFocusVisible, buttonProps } = useButtonProps({
    onClick,
    type: 'button',
    INTERNAL_isFocusVisible,
    isDisabled: isDisabled || isLoading,
    ...ariaProps,
  })

  return (
    <BaseActionBarButton
      variant={variant}
      wrapper={{ as: 'button', ref }}
      startIcon={startIcon}
      isLoading={isLoading}
      isFocusVisible={isFocusVisible}
      data-id={dataId}
      data-test-id={dataTestId}
      INTERNAL_isOnlyChild={INTERNAL_isOnlyChild}
      INTERNAL_hasNextSibling={INTERNAL_hasNextSibling}
      tooltip={tooltip}
      {...buttonProps}
    >
      {children}
    </BaseActionBarButton>
  )
}
ActionBarButton.dsId = 'action-bar-button'
