import styled, { css, DefaultTheme, StyledComponent } from 'styled-components'
import CSS from 'csstype'
import {
  compose,
  flexGrow,
  FlexGrowProps,
  flexShrink,
  FlexShrinkProps,
  FontSizeProps,
  FontStyleProps,
  FontWeightProps,
  layout,
  LayoutProps,
  LetterSpacingProps,
  LineHeightProps,
  ResponsiveValue,
  space,
  SpaceProps,
  system,
  TextAlignProps,
  TLengthStyledSystem,
  typography,
} from 'styled-system'
import React from 'react'
import { Theme, ThemeColors } from '../../theme'
import {
  ThemeFontFamily,
  ThemeFontSizes,
  ThemeFontWeights,
  ThemeLineHeights,
  ThemeOpacities,
} from '../../theme/types'
import { textPropsByTextStyle } from '../../theme/textStyles'
import { TextStyle } from './helpers'
import { TextStyleValue } from './types'

type TextProps = LayoutProps<Theme> &
  SpaceProps<Theme, CSS.Property.Margin<TLengthStyledSystem>> &
  FlexGrowProps<Theme> &
  FlexShrinkProps<Theme> &
  FontSizeProps<Theme, ThemeFontSizes | CSS.Property.FontSize<TLengthStyledSystem>> &
  FontWeightProps<Theme, ThemeFontWeights | CSS.Property.FontWeight> &
  LetterSpacingProps<Theme, CSS.Property.LetterSpacing<TLengthStyledSystem>> &
  LineHeightProps<Theme, ThemeLineHeights | CSS.Property.LineHeight<TLengthStyledSystem>> &
  FontStyleProps<Theme> &
  TextAlignProps<Theme> & {
    color?: ResponsiveValue<ThemeColors, Theme>
    fontFamily?: ResponsiveValue<ThemeFontFamily | CSS.Property.FontFamily, Theme>
    opacity?: ResponsiveValue<ThemeOpacities | CSS.Property.Opacity, Theme>
    overflowWrap?: ResponsiveValue<CSS.Property.OverflowWrap, Theme>
    textDecoration?: ResponsiveValue<CSS.Property.TextDecoration, Theme>
    textDecorationColor?: ResponsiveValue<ThemeColors, Theme>
    textOverflow?: ResponsiveValue<CSS.Property.TextOverflow, Theme>
    textTransform?: ResponsiveValue<CSS.Property.TextTransform, Theme>
    whiteSpace?: ResponsiveValue<CSS.Property.WhiteSpace, Theme>
    wordBreak?: ResponsiveValue<CSS.Property.WordBreak, Theme>
  }

const textCss = css<TextProps>`
  ${compose(typography, space, layout, flexGrow, flexShrink)}
  ${system({
    opacity: {
      property: 'opacity',
      scale: 'opacities',
    },
    overflowWrap: {
      property: 'overflowWrap',
    },
    textTransform: {
      property: 'textTransform',
    },
    textDecoration: {
      property: 'textDecoration',
    },
    textDecorationColor: {
      property: 'textDecorationColor',
      scale: 'colors',
    },
    textOverflow: {
      property: 'textOverflow',
    },
    whiteSpace: {
      property: 'whiteSpace',
    },
    wordBreak: {
      property: 'wordBreak',
    },
    bg: {
      property: 'background', // the default was 'backgroundColor' but we need to use gradients too
      scale: 'colors',
    },
    color: {
      property: 'color',
      scale: 'colors',
    },
  })}
`

export const TextBase = styled.span<TextProps>`
  ${({ theme }) => css`
    font-family: ${theme.fonts.primary};
    line-height: ${theme.lineHeights['none']};
    font-size: ${theme.fontSizes[14]};
    color: ${theme.colors['text/body-major']};
  `}
  ${textCss}
`

TextBase.displayName = 'TextBase'

type MapToResponsiveValue<T> = {
  [key in keyof T]: ResponsiveValue<T[key], Theme>
}

export const withTextStyles = <
  PropsType extends MapToResponsiveValue<TextStyleValue>,
  ElementType extends keyof JSX.IntrinsicElements | React.ComponentType<any>
>(
  component: StyledComponent<ElementType, DefaultTheme>,
  defaultProps?: Partial<PropsType & { textStyle?: TextStyle }>
) =>
  styled(component).attrs<PropsType & { textStyle?: TextStyle }>(props => {
    const { textStyle, fontFamily, fontSize, fontWeight, lineHeight, textTransform } = {
      ...defaultProps,
      ...props,
    } as typeof props
    if (textStyle && textPropsByTextStyle[textStyle as TextStyle]) {
      const textProps = textPropsByTextStyle[textStyle as TextStyle]
      return {
        ...defaultProps,
        fontFamily: fontFamily ?? textProps.fontFamily,
        fontSize: fontSize ?? textProps.fontSize,
        fontWeight: fontWeight ?? textProps.fontWeight,
        lineHeight: lineHeight ?? textProps.lineHeight,
        textTransform:
          textTransform ?? ('textTransform' in textProps ? textProps.textTransform : undefined),
      }
    }
  })`` as unknown as StyledComponent<
    ElementType,
    DefaultTheme,
    PropsType & { textStyle?: TextStyle }
  >

export const Text = withTextStyles<TextProps, 'span'>(TextBase)

/** @deprecated Use Text instead */
export const LabelText = styled(TextBase).attrs(props => ({
  color: 'text/body-moderate',
  fontWeight: 600,
  mb: 2,
  display: 'block',
  as: 'label',
  ...props,
}))<{ htmlFor?: string }>``

export const TextEllipsis = styled(Text).attrs(props => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  display: 'block',
  width: '100%',
  ...props,
}))``
